import FooterInView from "../components/FooterInView";
import Menu from "../components/Menu";

function ErrorPage() {
  let errorImage = "./assets/images/404-bg.webp";
  return (
    <>
      <Menu></Menu>
      <div className="container">
        <div className="d-flex justify-content-center">
          <img
            src={errorImage}
            alt="pageerror"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </div>
        <div className="" style={{ marginTop: "-5em" }}>
          <div className="d-flex justify-content-center">
            {/* <h1>Oops! Page not found</h1> */}
            <h1>Sorry!</h1>
          </div>
          <div className="d-flex justify-content-center">
            <p>Oops. We are not able to find what you are looking for.</p>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <a className="btn btn-primary backhome" href="/">
              Back to the Home Page
            </a>
          </div>
        </div>
      </div>
      <FooterInView></FooterInView>
    </>
  );
}
export default ErrorPage;
