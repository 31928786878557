import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import TitlesPage from "./pages/TitlesPage";
import ErrorPage from "./pages/ErrorPage";
import BlogsPage from "./pages/BlogsPage";
import MoviePage from "./pages/MoviePage";
import BlogInner from "./components/BlogInner";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/titles" element={<TitlesPage></TitlesPage>} /> */}
        <Route path="/blogs" element={<BlogsPage />} />
        <Route path="/errorpage" element={<ErrorPage></ErrorPage>} />
        <Route path="/blogs/:id" element={<BlogInner />} />
        <Route path="*" element={<ErrorPage></ErrorPage>} />
      </Routes>
    </Router>
  );
}

export default App;
