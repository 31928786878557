import React, { useState } from "react";
import Collapse from "react-bootstrap/Collapse";

const cname = "fawuc";
const coname = "ft_gdprUserConsent";
function UserConsent() {
  const [open, setOpen] = useState(false);
  const [smOpen, setSMOpen] = useState(false);

  let flag = false;
  if (checkConsentFlag() === "yes") {
    flag = true;
  }
  const [state, setState] = useState(flag);
  const setConsentFlag = () => {
    window.localStorage.setItem(cname, "true");
    setState(true);
  };

  const handleAgree = () => {
    storeValues();
    //window.localStorage.setItem(cname, "true");
    setGeneralCookie("gdprUserConsent", "yes", 365);
    setState(true);
  };

  const handleDecline = () => {
    setState(true);
  };
  let closeButton = "./assets/images/ico-modalclose.png";

  if (state) {
    return "";
  } else {
    return (
      <div id="gdpr_cookie_info_bar-wr" className="">
        <div className="consent-box">
          <button
            className="gdpr-close-button gtm-gdpr-close-button"
            id="gdprCloseButton"
            onClick={handleDecline}
          >
            <img className="gdpr-close-img" src={closeButton} />
          </button>

          <div className="text">
            <p>
              This website uses cookies to improve the functionality,
              performance, and effectiveness of our user experience. For more
              information on our data policies, please visit our{" "}
              <a
                id="gdprPrivacyLink"
                className="gdpr-privacy-link"
                href="https://futuretodayinc.com/privacy/"
                target="_blank"
              >
                privacy policy
              </a>
              . By clicking "I Agree," you consent to the use of cookies.
              <a
                className="gdpr-privacy-link gdpr-more-info gtm-gdpr-more-info"
                role="button"
                onClick={() => setOpen(!open)}
                aria-expanded={open}
                aria-controls="collapseCookieList"
              >
                {open ? "Hide Info" : "More Info"}
              </a>
            </p>
            <Collapse in={open}>
              <div className="collapse" id="collapseCookieList">
                <div
                  className=""
                  style={{
                    backgroundColor: `#fff`,
                    textAlign: "left",
                    paddingBottom: "20px",
                  }}
                >
                  <div className="cookie-list-table-wrapper">
                    <table className="cookie-list-table">
                      <thead>
                        <tr>
                          <th>Cookie Name</th>
                          <th>Cookie Value</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            &lt;Video Node ID&gt; &nbsp;&nbsp; Eg., 10458210
                          </td>
                          <td>
                            &lt;Video's previous watched duration in
                            milliseconds&gt; &nbsp;&nbsp; Eg., 1100
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>_token</td>
                          <td>
                            Encrypted token to fetch the API response, required
                            for security purposes
                          </td>
                        </tr>
                      </tbody>
                      <tbody>
                        <tr>
                          <td>appInstallDL</td>
                          <td>
                            To show / hide the Install App popup for mobile
                            screeens
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="buttons">
            <button
              className="gdpr-infobar-allow-all gtm-gdpr-infobar-allow-all grpr-button"
              onClick={handleAgree}
            >
              Accept
            </button>
            <button
              className="decline gdpr-infobar-dec-all gtm-gdpr-infobar-dec-all grpr-button"
              onClick={handleDecline}
            >
              Decline
            </button>
          </div>
        </div>

        <div className="consent-box-mobile d-none">
          <button
            className="gdpr-close-button gtm-gdpr-close-button"
            id="gdprCloseButtonSM"
            onClick={handleDecline}
          >
            <img className="gdpr-close-img" src={closeButton} />
          </button>
          <div className="consent-text text">
            <div>
              This website uses cookies to improve the functionality,
              performance, and effectiveness of our user experience. For more
              information on our data policies, please visit our{" "}
              <a
                id="gdprPrivacyLinkSM"
                className="gdpr-privacy-link"
                href="https://futuretodayinc.com/privacy/"
                target="_blank"
              >
                privacy policy
              </a>
              . By clicking "I Agree," you consent to the use of cookies.
              <a
                className="gdpr-privacy-link gdpr-more-info-sm gtm-gdpr-more-info"
                role="button"
                onClick={() => setSMOpen(!smOpen)}
                aria-controls="collapseCookieListSM"
                aria-expanded={smOpen}
              >
                {smOpen ? "Hide Info" : "More Info"}
              </a>
              <Collapse in={smOpen}>
                <div className="collapse" id="collapseCookieListSM">
                  <div
                    className=""
                    style={{
                      backgroundColor: `#fff`,
                      textAlign: "left",
                      paddingBottom: "20px",
                    }}
                  >
                    <div className="cookie-list-table-wrapper">
                      <table className="cookie-list-table">
                        <thead>
                          <tr>
                            <th>Cookie Name</th>
                            <th>Cookie Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              &lt;Video Node ID&gt; &nbsp;&nbsp; Eg., 10458210
                            </td>
                            <td>
                              &lt;Video's previous watched duration in
                              milliseconds&gt; &nbsp;&nbsp; Eg., 1100
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td>_token</td>
                            <td>
                              Encrypted token to fetch the API response,
                              required for security purposes
                            </td>
                          </tr>
                        </tbody>
                        <tbody>
                          <tr>
                            <td>appInstallDL</td>
                            <td>
                              To show / hide the Install App popup for mobile
                              screeens
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>
          <div className="consent-buttons">
            <button
              className="agree gdpr-infobar-allow-all gtm-gdpr-infobar-allow-all grpr-button mb-1"
              onClick={handleAgree}
            >
              I Agree
            </button>
            <button
              className="decline gdpr-infobar-dec-all gdpr-infobar-dec-all-sm gtm-gdpr-infobar-dec-all grpr-button mt-1"
              onClick={handleDecline}
            >
              Decline
            </button>
          </div>
        </div>
      </div>
    );
    return (
      <>
        <div id="cookieConsent" className="cookie-consent show-consent">
          <span className="cookie-text">
            We use cookies to ensure you have the best browsing experience on
            our website. By using our site, you acknowledge that you have read
            and understood our&nbsp;
            <a
              href="http://futuretodayinc.com/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Terms of Use</u>
            </a>
            &nbsp;&amp;&nbsp;
            <a
              href="http://futuretodayinc.com/privacy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <u>Privacy Policy</u>
            </a>
          </span>
          <button className="consent-btn" onClick={setConsentFlag}>
            Got It
          </button>

          {/* <div className="row">
            <div>
              <button className="consent-btn" onClick={handleAgree}>
                Agree
              </button>
              <button className="consent-btn" onClick={handleDecline}>
                Decline
              </button>
            </div>
          </div> */}
        </div>
      </>
    );
  }
}
function storeValues() {}
function checkConsentFlag() {
  return getCookieValue(coname);
  //return window.localStorage.getItem(cname);
}
function getCookieValue(cookieName) {
  const name = `${cookieName}=`;
  const decodedCookies = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookies.split(";");

  for (let cookie of cookieArray) {
    let trimmedCookie = cookie.trim();
    if (trimmedCookie.indexOf(name) === 0) {
      return trimmedCookie.substring(name.length, trimmedCookie.length);
    }
  }
  return null;
}

function setGeneralCookie(cname, cvalue, exdays) {
  cname = "ft_" + cname;
  var d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + "; " + expires + "; path=/";
}

export default UserConsent;
