function Trailer({ data }) {
  return (
    <div className="container mt-4">
      <div className="container-fluid">
        <div className="text-center">
          <h2>{data.title}</h2>
          {data.sub_tile ? <h2>{data.sub_tile}</h2> : ""}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-lg-6 p-4 trailer-left">
          <img src={data.image} className="heropic-de" alt={data.title} />
        </div>

        <div className="col-12 col-lg-6 p-4 trailer-right">
          <div>{data.description}</div>
          {/* style={{ textAlign: "justify" }} */}
          <a
            href={getTrailerLaunchUrl(data)}
            className="btn btn-primary mt-4 gtm-lp gtm-trailer-watch-now"
          >
            Watch Now
          </a>
        </div>
      </div>
    </div>
  );

  function getTrailerLaunchUrl(data) {
    let item = {
      movie_title: data.movie_title,
      node_id: data.node_id,
      itemType: data.itemType,
    };
    let urlFull = window.location.href;
    let url = urlFull;
    let qs = "";
    if (urlFull.indexOf("?") > -1) {
      url = urlFull.split("?")[0];
      qs = "?" + urlFull.split("?")[1];
    }
    let newUrl = url.replace(/\/#\/?$/, "");
    newUrl = window.location.protocol + "//" + window.location.hostname;
    let title = getCleanedTitle(item.movie_title);
    let nodeId = item.node_id;
    let base = "/";
    let itemType = "movies";
    console.log("item.itemType  ", item.itemType);
    if (item.itemType && item.itemType.toLowerCase() === "tv-shows") {
      itemType = "tv-shows";
    } else if (item.itemType && item.itemType.toLowerCase() === "category") {
      itemType = "category";
    } else if (item.itemType && item.itemType.toLowerCase() === "shows") {
      itemType = "shows";
    }
    //itemType = "tv-shows";
    let moviePath = `${itemType}/${nodeId}/${title}`;
    let moviePathWithSlash = base + moviePath;
    if (!newUrl.endsWith("/")) {
      moviePath = moviePathWithSlash;
    }
    let baseUrl = document.location.origin;
    //window.location.href = baseUrl + moviePath + qs;
    return baseUrl + moviePath + qs;
  }
  function getCleanedTitle(str) {
    str = str.toLowerCase().split(" ").join("-");
    /*// Replace all non-alphanumeric characters except hyphens with an empty string
		str = str.replace(/[^\w-]+/g, '-');
		// Replace consecutive hyphens with a single hyphen
		str = str.replace(/-{2,}/g, '-');
		return str;*/
    // Remove all non-alphanumeric characters except hyphens
    str = str.replace(/[^\w-]/g, "");
    // Remove consecutive hyphens
    str = str.replace(/-{2,}/g, "-");
    // Remove trailing hyphen (if present)
    str = str.replace(/-$/, "");
    return str;
  }
}
export default Trailer;
